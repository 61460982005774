export const environment = {
    production: true,
    envName: "fromTFS",
    clientID: "f8947502-f9fa-4f18-ae94-c68369d11c66",
    urlCloudApi: "https://devaccount.ansys.com/",
    authority: "https://login.microsoftonline.com/tfp/a365dev.onmicrosoft.com/B2C_1_Account_sign_in_dev",
    adminGroupId: "375184be-7f1e-4dc2-88a7-5bd245c3afd6",
    oidc: "https://devaccountregistration.ansys.com",
    oidcClientId: "portal",
    appInsights: { instrumentationKey: "aef44755-15c8-4df6-9e03-95e4876eb44c" },
};
